.toast {
  align-items: flex-start;
  font-weight: bold;
  font-size: 14px;

  .toast-separator {
    height: 1px;
    margin-bottom: 5px;
  }

  .toast-message {
    margin-bottom: 0px;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #000000;
  }
  div[role='status'] {
    display: flex;
    flex-direction: column; /* Stack items vertically inside status */
    margin-top: 0 !important;
    margin-bottom: 0px;
  }
}
